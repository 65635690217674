<template>
    <section>
        <the-navbar :public="true" />
        <div style="padding-top: 66px">
            <div class="section is-medium">
                <div class="card">
                    <header class="card-header">
                        <p class="card-header-title">Reestablecer Contraseña</p>
                    </header>
                    <div class="card-content">
                        <form @submit.prevent=""></form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheNavbar from '@/components/shared/Layout/TheNavbar.vue';

export default {
    name: 'ResetPassword',
    components: {
        TheNavbar,
    },
    methods: {},
};
</script>

<style>
</style>
